<template>
    <v-row align="start" class="row--35">
      <v-col lg="12" md="12" sm="12" cols="12" order="2" order-md="1">
        <div class="text-left section-title mb--50">
          <span class="subtitle">Hilf uns Probleme zu lösen</span>
          <h2 class="heading-title">Reiche dein Lösungskonzept ein</h2>
          <div class="im_address mt--5">
            <span>
              Hier kannst du deine Lösungskonzepte bis zum 20.01.2025 um 23:59 Uhr einreichen!<br>
              Mit dem Absenden deines Lösungskonzeptes stimmst du unseren <b><a href="https://ellavate.study/teilnahmebedingungen">Teilnahmebedingungen</a></b> zu.
            </span>
          </div>
        </div>
        <div class="form-wrapper">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider name="name" v-slot="{ errors }">
                <label>
                  <input
                    type="text"
                    v-model="formData.name"
                    placeholder="Vor- und Nachname"
                    required
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
  
              <ValidationProvider name="email" v-slot="{ errors }">
                <label>
                  <input
                    type="email"
                    v-model="formData.email"
                    placeholder="Hochschul-E-Mail"
                    required
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
  
              <ValidationProvider name="file" v-slot="{ errors }">
                <label>
                  <input
                    type="file"
                    @change="onFileChange"
                    accept=".pdf"
                    required
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
  
              <div class="im_address mt--5">
                <span><b>{{ status }}</b></span>
              </div>
  
              <button class="btn-default" type="submit" value="submit">
                Absenden
              </button>
            </form>
          </ValidationObserver>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import axios from "axios";
  
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        formData: {
          name: "",
          email: "",
          file: null,
        },
        status: "",
      };
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf" && file.size <= 10 * 1024 * 1024) {
          this.formData.file = file;
        } else {
          this.status = "Bitte lade eine gültige PDF-Datei hoch (max. 10 MB).";
          event.target.value = null;
        }
      },
      async onSubmit() {
        if (!this.formData.name || !this.formData.email || !this.formData.file) {
          this.status = "Bitte fülle alle Felder korrekt aus.";
          return;
        }
  
        const formData = new FormData();
        formData.append("name", this.formData.name);
        formData.append("email", this.formData.email);
        formData.append("file", this.formData.file);
  
        try {
          const response = await axios.post("https://api.ellavate.study/problem", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status === 200) {
            this.status = "Vielen Dank für deine Einreichung! Halte unseren Instagram-Account (ellavate.study) im Auge um auf dem neusten Stand zu bleiben.";
            this.resetForm();
            // setTimeout(() => {
            //   location.reload();
            // }, 10000);
          }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                this.status = err.response.data.message;
            } else {
                this.status = "Etwas ging schief. Bitte versuche es nochmal oder kontaktiere uns.";
            }
        }
      },
      resetForm() {
        this.formData.name = "";
        this.formData.email = "";
        this.formData.file = null;
      },
    },
  };
  </script>
  
  <style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
  </style>
  