<template>
  <div>
    <!-- Start Header Area -->
    <Header />
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="pt--80">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Challenge: Socializing
              </h2>
              <!-- <p>
                Nie wieder allein in Trier Aperoli trinken gehen!Mach jetzt nette Bekanntschaften wie unsere Ella!
              </p> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-6">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Die Challenge</h2>
                      <p class="description mt--30">
                        Erstelle ein neues Konzept für eine Socializing-Eventreihe an der Hochschule Trier, um neue Leute kennenzulernen! 
                        Ob es nun ein Netzwerk bezüglich deiner eigenen Zukunft ist oder ob du neue Freunde kennenlernen möchtest, ist ganz egal!
                        Es ist immer von Vorteil, neue Menschen kennenzulernen und auch einfach schön mit Studierenden aus anderen Bereichen interessante Gespräche zu führen, damit man verschiedene Themen aus einem anderen Blickwinkel betrachten kann.
                        Allerdings ist es schwer, innerhalb der Hochschule Kontakt mit Menschen aus anderen Fachbereichen zu knüpfen.
                        Hilf uns dabei, dass jeder so wie Ella sein kann!
                      </p>

                      <h4 class="heading-title">Gewinne 500€ mit deiner Lösung</h4>
                        <p class="description mt--10">
                          Jedes gewinnende Konzept einer der drei Challenges wird mit einem Preisgeld von 500 Euro prämiert.
                        </p>

                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="portfolio-details-accordion mt_lg--40 mt_md--40 mt_sm--40"
                  >
                    <div class="inner">
                      <div class="section-title">

                        <h4 class="heading-title">Dein Lösungskonzept</h4>
                        <p class="description mt--30">
                          Du entwickelst ein kreatives und innovatives Konzept für eine Socializing-Eventreihe, 
                          welches den langfristigen Austausch zwischen verschiedenen Fachbereichen fördert und gleichzeitig Spaß macht. 
                          Das Ziel ist es, das Vernetzen und Interagieren im Alltag der Studierenden zu erleichtern und mehr Möglichkeiten für interessante Gespräche, 
                          gemeinsame Aktivitäten und längerfristige Kontakte zu bieten.
                        </p>
                        <p class="description mt--30">
                          Die Einreichung kann in Form einer
                          PowerPoint-Präsentation oder eines Papers erfolgen. Die PowerPoint-Präsentation (im
                          PDF-Format) sollte alle notwendigen Informationen enthalten, um das Konzept
                          vollständig zu verstehen und maximal 10MB groß sein. Nutze das Formular auf der Seite der Challenge um dein Lösungskonzept hochzuladen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">

                <!-- Container Start-->
                <div class="col-lg-12">
                  <div
                    class="portfolio-details-accordion mt_lg--40 mt_md--40 mt_sm--40"
                  >
                    <div class="inner">
                      <div class="section-title">
                        <h4 class="heading-title">Wie es funktioniert</h4>
                        <p class="description mt--30">
                          Der Wettbewerb besteht aus drei Phasen:<br><br>
                          <b>1. Einreichungsphase:</b> <br> Erstelle und reiche dein Konzept bis spätestens zum 20.
                          Januar 2025 um 23:59 Uhr ein.
                          <br><br>
                          <b>2. Bewertungsphase:</b> <br> Unsere Jury, bestehend aus Sponsoren und Spezialisten der
                          Hochschule, prüft die Konzepte und wählt die beste Lösung für jede Challenge aus.
                          <br><br>
                          <b>3. Bekanntgabe der Gewinner:</b> <br> Die Preisverleihung findet Anfang des Sommersemesters 2025 statt. Die Gewinner/innen werden im Vorfeld per E-Mail von uns kontaktiert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Container Ende-->

              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">

                <!-- Container Start-->
                <div class="col-lg-12">
                  <div
                    class="portfolio-details-accordion mt_lg--40 mt_md--40 mt_sm--40"
                  >
                    <div class="inner">
                      <div class="section-title">
                        <h4 class="heading-title">Wettbewerbsregeln</h4>
                        <p class="description mt--30">
                          <b>Einreichungsqualitätsstandards:</b>
                          Sei kreativ und lasse deiner Fantasie freien Lauf. Es gibt keine Ober- oder Untergrenze
                          bezüglich einer Seitenzahl. Bitte stelle sicher, dass deine eingereichte Lösung detailliert
                          und gut durchdacht ist. Wir möchten sehen, dass du dich mit dem Projekt
                          auseinandergesetzt und dir Mühe gegeben hast, eine realistische und kreative Lösung zu
                          entwickeln.
                          <br><br>
                          <b>Bewertung:</b>
                            Eine Jury, bestehend aus Mitgliedern des Hochschulteams und Sponsoren, wählt die
                            besten Einreichungen, basierend auf:
                            <br>

                            <ol class="ml--30">

                              <li>Gesamteindruck und Originalität</li>
                                <ul class="ml--30">
                                  <li>Innovation: Wie originell und kreativ ist das Konzept?</li>
                                  <li>Wow-Faktor: Hat die Idee das Potenzial, Begeisterung und Interesse bei den
                                    Studierenden zu wecken?</li>
                                </ul>
                              
                              <li>Durchdachtheit und Details</li>
                                <ul class="ml--30">
                                  <li>Detaillierungsgrad: Ist die Lösung detailliert genug beschrieben, um sie vollständig zu verstehen?</li>
                                  <li>Umsetzbarkeit: Sind die Schritte zur Umsetzung klar und realistisch, wie und wann
                                      erfolgen die einzelnen Schritte deiner Lösung und wie begeistert du die
                                      Studierenden von deiner Idee?</li>
                                </ul>

                              <li>Relevanz zum Wettbewerbsthema</li>
                                <ul class="ml--30">
                                  <li>Zielerreichung: Erfüllt die Lösung die Hauptziele der Challenge?</li>
                                  <li>Langfristigkeit: Hat die Lösung das Potenzial langfristig zu funktionieren?</li>
                                </ul>

                              <li>Ressourcenmanagement</li>
                                <ul class="ml--30">
                                  <li>Budgeteinhaltung: Wie viel Budget wird für die Umsetzung der Lösung benötigt? Wie kann das Geld, welches für die Umsetzung benötigt wird, eingenommen werden?</li>
                                  <li>Ressourcennutzung: Werden die vorhandenen Ressourcen optimal genutzt?</li>
                                </ul>

                            </ol>
                          
                        </p>
                        <p class="description mt--30">
                          Die geltenden <b>Teilnahmebedingungen</b> können unter <a href="https://ellavate.study/teilnahmebedingungen">https://ellavate.study/teilnahmebedingungen</a> abgerufen werden.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Container Ende-->

              </div>
            </div>
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <!-- Loesung -->
            <div class="rn-contact-area ptb--100">
              <div class="contact-form--1">
                <v-container>
                  <Loesung />
                </v-container>
              </div>
            </div>
          </v-col>
        </v-row>

        
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/ellavate/Header_ext";
  import Footer from "../../components/ellavate/Footer";
  import Loesung from "../../components/ellavate/Loesung";

  import feather from "feather-icons";

  export default {
    components: {
      Header,
      Footer,
      Loesung,
    },
    data() {
      return {
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
